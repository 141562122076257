import Button from '@/components/button'
import Back from './_back'
import { PersonaFormProps } from './_common'
import { useCallback, useEffect, useState } from 'react'
import { cls } from '@/utils'
import { PersonaContentCategory, PersonaRole } from '@/types'
import IconFilm from '@/public/assets/persona/film.svg'
import IconSports from '@/public/assets/persona/sport.svg'
import IconVlog from '@/public/assets/persona/flower.svg'
import IconPerformance from '@/public/assets/persona/music.svg'
import IconNews from '@/public/assets/persona/news.svg'
import IconAnimation from '@/public/assets/persona/cartoon.svg'
import IconGaming from '@/public/assets/persona/game.svg'
import IconFashion from '@/public/assets/persona/lipstick.svg'
import IconLearning from '@/public/assets/persona/pen-tool.svg'
import IconOther from '@/public/assets/persona/loading.svg'
import { Checkbox } from '@/components/ui/checkbox'
import useAmplitude from '@/hooks/useAmplitude'

const options = [
  {
    value: 'film_tv',
    icon: IconFilm,
    label: 'Film / TV',
  },
  {
    value: 'sports',
    icon: IconSports,
    label: 'Sports',
  },
  {
    value: 'vlog_live',
    icon: IconVlog,
    label: 'Vlog / Live',
  },
  {
    value: 'performance',
    icon: IconPerformance,
    label: 'Performance',
  },
  {
    value: 'news',
    icon: IconNews,
    label: 'News',
  },
  {
    value: 'animation',
    icon: IconAnimation,
    label: 'Animation',
  },
  {
    value: 'gaming',
    icon: IconGaming,
    label: 'Gaming',
  },
  {
    value: 'fashion',
    icon: IconFashion,
    label: 'Fashion & Beauty',
  },
  {
    value: 'learning',
    icon: IconLearning,
    label: 'Learning',
  },
  {
    value: 'other',
    icon: IconOther,
    label: 'Other',
  },
]

export default function PersonaPreferedContentCategoriesForm({
  onSubmit,
  onBack,
  value,
}: PersonaFormProps) {
  const [categories, setCategories] = useState<PersonaContentCategory[]>(
    value?.prefered_content_categories ?? [],
  )

  const { track } = useAmplitude()
  useEffect(() => {
    track('view:persona:perfered-content-categories')
  }, [track])

  const handleSubmit = useCallback(() => {
    if (categories) {
      onSubmit({ prefered_content_categories: categories })
    }
  }, [categories, onSubmit])

  return (
    <div className='size-full flex flex-col items-center justify-center'>
      <div className='w-full md:w-[640px] flex flex-col items-start justify-center'>
        <div
          className='w-full p-6 text-heading-lg font-bold'
          aria-label='title'
        >
          What types of videos do you enjoy?
        </div>
        <div
          className='w-full px-6 grid grid-cols-1 md:grid-cols-2 gap-4'
          aria-label='radio'
        >
          {options.map(({ label, value, icon: Icon }) => {
            const id = `persona-content-category-${value}`
            const active = categories?.includes(value as PersonaContentCategory)
            return (
              <div
                key={value}
                className={cls(
                  'group relative flex items-center w-full justify-between h-[66px] rounded-lg border-2 border-b-4 border-solid border-border shadow-sm cursor-pointer hover:bg-surface-hover hover:border-border-hover',
                  active
                    ? 'bg-surface-active hover:bg-surface-active bg-opacity-10 border-border-active hover:border-border-active'
                    : 'bg-surface',
                )}
              >
                <label
                  htmlFor={id}
                  className='size-full min-h-full flex items-center gap-4 h-9 p-3 cursor-pointer'
                >
                  <Icon className='w-[34px] h-auto max-h-[34px]' />
                  <span className='text-heading-md font-bold'>{label}</span>
                </label>
                <div className='p-1.5 size-9 flex items-center justify-center absolute h-full right-3 inset-y-0 pointer-events-none'>
                  <Checkbox
                    id={id}
                    size='sm'
                    className={cls(
                      'border-2 border-solid border-border rounded-[4px] flex items-center justify-center pointer-events-auto group-hover:border-border-hover',
                      active ? 'group-hover:border-border-active' : '',
                    )}
                    value={value}
                    checked={active}
                    onCheckedChange={(checked) => {
                      if (checked) {
                        setCategories([
                          ...categories,
                          value as PersonaContentCategory,
                        ])
                      } else {
                        setCategories(categories.filter((c) => c !== value))
                      }
                    }}
                  />
                </div>
              </div>
            )
          })}
        </div>
        <div className='w-full p-6 pt-4 flex justify-between'>
          <Back onBack={onBack} />
          <Button
            variant='primary'
            className='rounded-md'
            disabled={!categories?.length}
            onClick={handleSubmit}
          >
            Confirm
          </Button>
        </div>
      </div>
    </div>
  )
}
