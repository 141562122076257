import Button from '@/components/button'
import { PersonaFormProps } from './_common'
import IconMovies from '@/public/assets/persona/movies.svg'
import IconYes from '@/public/assets/yes.svg'
import IconNo from '@/public/assets/persona/no.svg'
import Back from './_back'
import { useCallback, useEffect } from 'react'
import useAmplitude from '@/hooks/useAmplitude'
import { cls } from '@/utils'

export default function PersonaExperiencedVideoToolsForm({
  value,
  onSubmit,
  onBack,
}: PersonaFormProps) {
  const handleYes = useCallback(() => {
    onSubmit({ experienced_video_tools: true })
  }, [onSubmit])

  const handleNo = useCallback(() => {
    onSubmit({ experienced_video_tools: false })
  }, [onSubmit])

  const { track } = useAmplitude()
  useEffect(() => {
    track('view:persona:experienced-video-tools')
  }, [track])

  const buttonStyle =
    'p-3 px-2 flex items-center flex-1 w-full justify-start rounded-lg border-2 border-b-4 border-solid border-border hover:border-border-hover shadow-sm'
  const activeColor =
    'bg-surface-active hover:bg-surface-active border-border-active hover:border-border-active'

  return (
    <div className='size-full flex items-center justify-center'>
      <div className='md:w-[480px] h-[363px]'>
        <div
          className='w-full p-6 text-heading-lg font-bold tracking-45'
          aria-label='title'
        >
          Experienced with video tools?
        </div>
        <div className='w-full p-6 pt-0 flex gap-5 h-[104px] items-center'>
          <div className='size-[75px] p-[2.5px]'>
            <IconMovies className='size-[75px]' />
          </div>
          <div className='text-heading-md font-bold'>
            Such as:
            <br />
            Adobe Premiere Pro, Final Cut Pro, After Effects, CapCut, DaVinci
            Resolve, iMovie, etc.
          </div>
        </div>
        <div className='px-6 gap-4 w-full flex h-[66px]'>
          <Button
            className={cls(
              buttonStyle,
              value?.experienced_video_tools === true ? activeColor : '',
            )}
            onClick={handleYes}
          >
            <div className='flex w-full justify-start items-center gap-4'>
              <IconYes className='w-[34px] h-auto' />
              <span className='text-text text-heading-md font-bold'>Yes</span>
            </div>
          </Button>
          <Button
            className={cls(
              buttonStyle,
              value?.experienced_video_tools === false ? activeColor : '',
            )}
            onClick={handleNo}
          >
            <div className='flex w-full justify-start items-center gap-4'>
              <IconNo className='w-[34px] h-auto' />
              <span className='text-text text-heading-md font-bold'>No</span>
            </div>
          </Button>
        </div>
        <div className='flex justify-between w-full items-center p-6 pt-4'>
          <Back className='' onBack={onBack} />
          <div />
        </div>
      </div>
    </div>
  )
}
