'use client'
import { useCallback, useRef, useState } from 'react'
import { Modal } from '@/components/modal'
import useAmplitude from '@/hooks/useAmplitude'
import { cls, setLocalStorage } from '@/utils'
import Dialog from '@/components/dialog'
import { DialogCloseButtonClassName } from '../ui/dialog'
import IconExit from '@haiper/icons-svg/icons/outline/logout.svg'
import Button from '@/components/button'
import PersonaUsedForForm from './_used-for'
import PersonaAgeGroupForm from './_age-group'
import PersonaRoleForm from './_role'
import PersonaSourceForm from './_source'
import PersonaEnd from './_end'
import PersonaExperiencedAIGCToolsForm from './_experienced_aigc_tools'
import PersonaExperiencedVideoToolsForm from './_experienced_video_tools'
import { Persona } from '@/types'
import { useAtom } from 'jotai'
import { personaAtom, surveyCompleteAtom } from '@/atoms'
import PersonaNicknameForm from './_nick-name'
import PersonaPreferedContentCategoriesForm from './_prefered_content_categories'
import { useCachedMyProfile } from '@/hooks/useMyProfile'
import { postUserSurvey } from '@/service/persona.service'
import PersonaPurposeForm from './_purpose'
import PersonaShareForm from './_share'

const steps = [
  'source',
  'age_group',
  'prefered_content_categories',
  'role',
  // 'nickname',
  'used_for',
  'purpose',
  'share',
  'experienced_video_tools',
  'experienced_aigc_tools',
  'end',
]

const stepComponentMap = {
  source: PersonaSourceForm,
  used_for: PersonaUsedForForm,
  nickname: PersonaNicknameForm,
  age_group: PersonaAgeGroupForm,
  role: PersonaRoleForm,
  purpose: PersonaPurposeForm,
  share: PersonaShareForm,
  prefered_content_categories: PersonaPreferedContentCategoriesForm,
  experienced_video_tools: PersonaExperiencedVideoToolsForm,
  experienced_aigc_tools: PersonaExperiencedAIGCToolsForm,
  end: PersonaEnd,
}

export default function PersonaDialog() {
  const { data: profile } = useCachedMyProfile()

  const [persona, setPersona] = useAtom(personaAtom)

  const [surveyComplete, setSurveyComplete] = useAtom(surveyCompleteAtom)

  const [visible, setVisible] = useState(true)
  const [confirmDialogVisible, setConfirmDialogVisible] = useState(false)

  const { track } = useAmplitude()

  const handleOpenChange = useCallback(
    (open: boolean) => {
      if (!open) {
        track('click:persona:try-close')
        setConfirmDialogVisible(true)
      }
    },
    [track],
  )

  const handleExit = useCallback(
    (e: any) => {
      e?.preventDefault()
      e?.stopPropagation()
      track('click:persona:exit')
      setConfirmDialogVisible(false)
      setVisible(false)
      setSurveyComplete(false)
    },
    [track, setSurveyComplete],
  )

  const handleStay = useCallback(
    (e: any) => {
      e?.preventDefault()
      e?.stopPropagation()
      track('click:persona:stay')
      setConfirmDialogVisible(false)
    },
    [track],
  )

  const handleConfirmDialogOpenChange = useCallback((open: boolean) => {
    setConfirmDialogVisible(open)
  }, [])

  const currentStep =
    persona?.step && steps.includes(persona.step) ? persona.step : steps[0]

  const apiPromiseRef = useRef<Promise<any> | null>(null)

  const handleGoNext = useCallback(
    async (values: Partial<Persona>) => {
      if (currentStep === 'end') {
        setSurveyComplete(true)
        await apiPromiseRef.current
        return
      }
      let nextStepIndex = steps.indexOf(currentStep) + 1
      if (currentStep === 'used_for') {
        nextStepIndex =
          values.used_for === 'work'
            ? steps.indexOf('purpose')
            : steps.indexOf('share')

        if (values.used_for === 'work') {
          values.share = undefined
        } else if (values.used_for === 'fun') {
          values.purpose = undefined
          values.purpose_other = undefined
        }
      } else if (currentStep === 'purpose') {
        // skip share if user is purpose for work
        nextStepIndex = nextStepIndex + 1
      }
      const nextStep = steps[nextStepIndex]

      setPersona((prev) => {
        const newValue = {
          ...prev,
          ...values,
          survey_version: '2024-04-23',
          step: nextStep as any,
        }
        if (nextStep === 'end') {
          apiPromiseRef.current = postUserSurvey(newValue)
        }
        setLocalStorage('persona', newValue)
        return newValue
      })
    },
    [currentStep, setPersona, setSurveyComplete],
  )

  const handleGoBack = useCallback(() => {
    let prevStepIndex = steps.indexOf(currentStep) - 1
    if (['purpose', 'share'].includes(currentStep)) {
      prevStepIndex = steps.indexOf('used_for')
    } else if (['purpose', 'share'].includes(steps[prevStepIndex])) {
      prevStepIndex =
        (persona?.purpose?.length ?? 0) > 0
          ? steps.indexOf('purpose')
          : steps.indexOf('share')
    }
    if (prevStepIndex >= 0) {
      const prevStep = steps[prevStepIndex]
      setPersona((prev) => ({
        ...prev,
        step: prevStep as any,
      }))
    }
  }, [currentStep, setPersona, persona])

  const renderSteps = () => {
    const StepComponent =
      stepComponentMap[currentStep as keyof typeof stepComponentMap]
    if (StepComponent) {
      return (
        <StepComponent
          value={persona}
          onSubmit={handleGoNext}
          onBack={handleGoBack}
        />
      )
    }
    return null
  }

  if (!profile || profile?.survey_completed || surveyComplete) {
    return null
  }

  return (
    <>
      <Modal
        withBroadcasts
        open={visible}
        className='pt-[48px] md:pt-10 shadow-none max-w-[100vw] md:max-w-[100vw] rounded-none sm:rounded-none'
        onOpenChange={handleOpenChange}
      >
        <div key={currentStep} className='size-full'>
          {renderSteps()}
        </div>
        <Dialog
          open={confirmDialogVisible}
          title={
            <div className='flex flex-col gap-3'>
              <div
                aria-label='icon'
                className='size-12 rounded-full bg-surface-active p-3'
              >
                <IconExit className='size-full text-icon-interactive ' />
              </div>
              <div className='w-full flex flex-col gap-1'>
                <div className='text-heading-lg font-bold'>Exit?</div>
                <div className='text-text-subdued text-body-md font-normal'>
                  You will lose your progress if you exit now
                </div>
              </div>
            </div>
          }
          titleClassName='mb-6'
          className={cls('gap-0 md:w-[343px] p-4')}
          footerClassName='mt-[30px]'
          footer={null}
          onOpenAutoFocus={(e) => {
            e?.preventDefault()
            if (!e.target) return
            const elSelf = e.target as HTMLElement
            const elClose = elSelf.querySelector(
              `.${DialogCloseButtonClassName}`,
            ) as HTMLButtonElement
            elClose?.focus?.()
          }}
          onOpenChange={handleConfirmDialogOpenChange}
        >
          <div className='flex flex-col gap-3'>
            <Button variant='primary' className='w-full' onClick={handleStay}>
              <span className='text-text-on-color'>Stay and continue</span>
            </Button>
            <Button variant='outline' className='w-full' onClick={handleExit}>
              <span className='text-text-critical'>Exit</span>
            </Button>
          </div>
        </Dialog>
      </Modal>
    </>
  )
}
