import Button from '@/components/button'
import Back from './_back'
import { PersonaFormProps } from './_common'
import { useCallback, useEffect, useState } from 'react'
import { cls } from '@/utils'
import IconOther from '@/public/assets/persona/loading.svg'
import { Checkbox } from '@/components/ui/checkbox'
import useAmplitude from '@/hooks/useAmplitude'
import IconAnnouncement from '@/public/assets/persona/annoncement-megaphone.svg'
import IconCompass from '@/public/assets/persona/compass-navigator.svg'
import IconFilm from '@/public/assets/persona/film.svg'
import IconMusic from '@/public/assets/persona/music.svg'
import IconBallon from '@/public/assets/persona/ballon.svg'
// import IconPaintPalette from '@/public/assets/persona/paint-palette.svg'
import Input from '@/components/input'

const options = [
  {
    value: 'advertisements',
    icon: IconAnnouncement,
    label: 'Advertisements',
  },
  // {
  //   value: 'ideation',
  //   icon: IconPaintPalette,
  //   label: 'Ideation',
  // },
  {
    value: 'exploration',
    icon: IconCompass,
    label: 'Exploration',
  },
  {
    value: 'story_telling',
    icon: IconFilm,
    label: 'Story telling',
  },
  {
    value: 'music_videos',
    icon: IconMusic,
    label: 'Music videos',
  },
  {
    value: 'entertainment',
    icon: IconBallon,
    label: 'Entertainment',
  },
  {
    value: 'other',
    icon: IconOther,
    label: 'Other',
    className: '',
  },
]

export default function PersonaPurposeForm({
  onSubmit,
  onBack,
  value,
}: PersonaFormProps) {
  const [purpose, setPurpose] = useState<string[]>(value?.purpose ?? [])

  const showInput = purpose.includes('other')
  const [purposeOther, setPurposeOther] = useState<string | ''>(
    value?.purpose_other ?? '',
  )

  const { track } = useAmplitude()
  useEffect(() => {
    track('view:persona:purpose')
  }, [track])

  const handleSubmit = useCallback(() => {
    if (purpose) {
      onSubmit({
        purpose,
        purpose_other: purposeOther,
      })
    }
  }, [purpose, onSubmit, purposeOther])

  return (
    <div className='size-full flex flex-col items-center justify-center'>
      <div className='w-full md:w-[640px] flex flex-col items-start justify-start h-[438px]'>
        <div
          className='w-full p-6 text-heading-lg font-bold'
          aria-label='title'
        >
          What are you creating?
        </div>
        <div
          className='w-full px-6 grid grid-cols-1 md:grid-cols-2 gap-4'
          aria-label='radio'
        >
          {options.map(({ label, value, icon: Icon, className }) => {
            const id = `persona-purpose-${value}`
            const active = purpose?.includes(value)
            return (
              <div
                key={value}
                className={cls(
                  'group relative flex items-center w-full justify-between rounded-lg border-2 border-b-4 border-solid border-border shadow-sm cursor-pointer hover:bg-surface-hover hover:border-border-hover',
                  className,
                  active
                    ? 'bg-surface-active hover:bg-surface-active bg-opacity-10 border-border-active hover:border-border-active'
                    : 'bg-surface',
                  showInput && value === 'other' ? '' : 'h-[66px]',
                )}
              >
                <label
                  htmlFor={id}
                  className={cls(
                    'size-full flex flex-col gap-4 p-3 cursor-pointer justify-center',
                  )}
                >
                  <div className='flex items-center gap-4 h-9'>
                    <Icon className='w-[34px] h-auto max-h-[34px]' />
                    <span className='text-heading-md font-bold'>{label}</span>
                    <div className='p-1.5 size-9 flex items-center justify-center ml-auto pointer-events-none'>
                      <Checkbox
                        id={id}
                        size='sm'
                        className={cls(
                          'border-2 border-solid border-border rounded-[4px] flex items-center justify-center pointer-events-auto group-hover:border-border-hover',
                          active ? 'group-hover:border-border-active' : '',
                        )}
                        value={value}
                        checked={active}
                        onCheckedChange={(checked) => {
                          if (checked) {
                            setPurpose([...purpose, value])
                          } else {
                            setPurpose(purpose.filter((c) => c !== value))
                          }
                        }}
                      />
                    </div>
                  </div>
                  {showInput && value === 'other' ? (
                    <Input
                      className='h-10 border rounded-md focus-visible:outline-none focus-visible:border-border-active cursor-auto min-w-full w-[568px]'
                      width={568}
                      autoComplete='off'
                      placeholder='Please specify'
                      value={purposeOther}
                      onChange={(e: any) => setPurposeOther(e.target.value)}
                      onClick={(e) => e.stopPropagation()}
                    />
                  ) : null}
                </label>
              </div>
            )
          })}
        </div>
        <div className='w-full p-6 pt-4 flex justify-between'>
          <Back onBack={onBack} />
          <Button
            variant='primary'
            className='rounded-md'
            disabled={!purpose?.length || (showInput && !purposeOther)}
            onClick={handleSubmit}
          >
            Confirm
          </Button>
        </div>
      </div>
    </div>
  )
}
