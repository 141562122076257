import useAmplitude from '@/hooks/useAmplitude'
import { PersonaFormProps } from './_common'
import IconChess from '@/public/assets/persona/chess-pawn.svg'
import IconWork from '@/public/assets/persona/tool-box.svg'
import { useEffect } from 'react'
import Back from './_back'
import { cls } from '@/utils'

const showConfirmBtn = false

export default function PersonaUsedForForm({
  value,
  onSubmit,
  onBack,
}: PersonaFormProps) {
  const cardStyle =
    'border-2 border-b-4 border-border hover:border-border-hover active:border-border-hover rounded-xl flex flex-col items-center justify-center flex-1 gap-2 p-3 h-[242px] bg-surface hover:bg-surface-hover cursor-pointer hover:border-border-hover'
  const activeColor =
    'bg-surface-active hover:bg-surface-active border-border-active hover:border-border-active'

  const { track } = useAmplitude()
  useEffect(() => {
    track('view:persona:used-for')
  }, [track])

  return (
    <div
      className='size-full items-center justify-center flex flex-col'
      aria-label='used-for-form'
    >
      <div
        className='text-heading-2xl font-sn font-bold hidden'
        aria-label='title'
      >
        Welcome to Haiper
      </div>
      <div className='mt-2 text-heading-lg font-bold' aria-label='question'>
        What will you use Haiper for?
      </div>
      <div
        className='p-6 pb-0 flex gap-4 w-full md:w-[480px] max-w-[480px]'
        aria-label='options'
      >
        <div
          className={cls(cardStyle, value?.used_for === 'fun' && activeColor)}
          aria-label='fun'
          role='button'
          onClick={() => {
            onSubmit({ used_for: 'fun' })
          }}
        >
          <IconChess className='h-[34px]' />
          <div className='text-heading-md font-bold'>For fun</div>
        </div>
        <div
          className={cls(cardStyle, value?.used_for === 'work' && activeColor)}
          aria-label='work'
          role='button'
          onClick={() => {
            onSubmit({ used_for: 'work' })
          }}
        >
          <IconWork className='h-[34px]' />
          <div className='text-heading-md font-bold'>For work</div>
        </div>
      </div>
      <div className='p-6 pt-4 flex gap-4 w-full md:w-[480px] max-w-[480px]'>
        <Back onBack={onBack} />
        <div />
      </div>
    </div>
  )
}
