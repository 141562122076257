import IconOkHand from '@/public/assets/persona/ok-hand.svg'
import { PersonaFormProps } from './_common'
import { useEffect } from 'react'

export default function PersonaEnd({ onSubmit }: PersonaFormProps) {
  useEffect(() => {
    setTimeout(() => {
      onSubmit({})
    }, 2 * 1000)
  }, [onSubmit])

  return (
    <div className='size-full flex justify-center items-center'>
      <div className='md:w-[480px] h-[356px] flex items-center justify-center flex-col gap-6'>
        <IconOkHand />
        <div className='text-heading-2xl font-sn font-bold'>
          Let’s get this party started!
        </div>
      </div>
    </div>
  )
}
