import Button from '@/components/button'
import Back from './_back'
import { PersonaFormProps } from './_common'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { cls } from '@/utils'
import { Checkbox } from '@/components/ui/checkbox'
import useAmplitude from '@/hooks/useAmplitude'
import IconX from '@/public/assets/persona/x.svg'
import IconXDark from '@/public/assets/persona/x-dark.svg'
import IconFriends from '@/public/assets/persona/friends.svg'
import IconUser from '@/public/assets/persona/user.svg'
import useTheme from '@/hooks/useTheme'

export default function PersonaShareForm({
  onSubmit,
  onBack,
  value,
}: PersonaFormProps) {
  const [share, setShare] = useState<string[]>(value?.share ?? [])
  const { theme } = useTheme()

  const options = useMemo(
    () => [
      {
        value: 'social_media',
        icon: theme == 'dark' ? IconXDark : IconX,
        label: 'Social Media',
      },
      {
        value: 'friends',
        icon: IconFriends,
        label: 'Friends',
      },
      {
        value: 'private',
        icon: IconUser,
        label: 'Private',
      },
    ],
    [theme],
  )

  const { track } = useAmplitude()
  useEffect(() => {
    track('view:persona:share')
  }, [track])

  const handleSubmit = useCallback(() => {
    if (share) {
      onSubmit({ share })
    }
  }, [share, onSubmit])

  return (
    <div className='size-full flex flex-col items-center justify-center'>
      <div className='w-full md:w-[640px] flex flex-col items-start justify-center'>
        <div
          className='w-full p-6 text-heading-lg font-bold'
          aria-label='title'
        >
          Share your videos?
        </div>
        <div className='w-full px-6 grid grid-cols-1 gap-4' aria-label='radio'>
          {options.map(({ label, value, icon: Icon }) => {
            const id = `persona-share-${value}`
            const active = share?.includes(value)
            return (
              <div
                key={value}
                className={cls(
                  'group relative flex items-center w-full justify-between h-[66px] rounded-lg border-2 border-b-4 border-solid border-border shadow-sm cursor-pointer hover:bg-surface-hover hover:border-border-hover',
                  active
                    ? 'bg-surface-active hover:bg-surface-active bg-opacity-10 border-border-active hover:border-border-active'
                    : 'bg-surface',
                )}
              >
                <label
                  htmlFor={id}
                  className='size-full min-h-full flex items-center gap-4 h-9 p-3 cursor-pointer'
                >
                  <Icon className='w-[34px] h-auto max-h-[34px]' />
                  <span className='text-heading-md font-bold'>{label}</span>
                </label>
                <div className='p-1.5 size-9 flex items-center justify-center absolute h-full right-3 inset-y-0 pointer-events-none'>
                  <Checkbox
                    id={id}
                    size='sm'
                    className={cls(
                      'border-2 border-solid border-border rounded-[4px] flex items-center justify-center pointer-events-auto group-hover:border-border-hover',
                      active ? 'hover:border-border-active' : '',
                    )}
                    value={value}
                    checked={active}
                    onCheckedChange={(checked) => {
                      if (checked) {
                        setShare([...share, value])
                      } else {
                        setShare(share.filter((c) => c !== value))
                      }
                    }}
                  />
                </div>
              </div>
            )
          })}
        </div>
        <div className='w-full p-6 pt-4 flex justify-between'>
          <Back onBack={onBack} />
          <Button
            variant='primary'
            className='rounded-md'
            disabled={!share?.length}
            onClick={handleSubmit}
          >
            Confirm
          </Button>
        </div>
      </div>
    </div>
  )
}
