'use client'

import { usePathname, useRouter } from 'next/navigation'
import { ReactNode, useEffect } from 'react'
import Loading from '@/components/loading'
import { useCachedMyProfile } from '@/hooks/useMyProfile'
import useAmplitude from '@/hooks/useAmplitude'
import useGoAuth from '@/hooks/useGoAuth'
import { useSetAtom } from 'jotai'
import { auth0Loading, auth0SignInAtom } from '@/atoms'
import { isOldUser } from '@/utils'
import useAuth0Auth from '@/hooks/useAuth0Auth'

const bizWhiteLists = [
  // /^\/membership$/,
  /^\/onboarding$/,
  /^\/haiper-api(\/.+)?$/,
  /^\/creation\/.+/,
  /^\/spotlight(\/.+)$/,
  // /^\/explore\/?$/,
]

const whiteLists = [
  /^\/auth\//,
  /^\/404/,
  /^\/debug\//,
  /^\/playground\//,
  // /^\/$/,
  ...bizWhiteLists,
]

export default function Guard({ children }: { children: ReactNode }) {
  const { isLogin, isLoading: auth0ProfileLoading, refreshToken } = useAuth0Auth()
  const setSignedIn = useSetAtom(auth0SignInAtom)
  const setAuth0Loading = useSetAtom(auth0Loading)
  const router = useRouter()

  const pathname = usePathname()
  const goAuth = useGoAuth()
  useEffect(() => {
    setSignedIn(isLogin)
    setAuth0Loading(auth0ProfileLoading)
  }, [isLogin, setSignedIn, auth0ProfileLoading, setAuth0Loading, refreshToken])

  const { data: userProfile, isValidating: userProfileLoading } = useCachedMyProfile()
  const { setUserCreateTime, setUserId } = useAmplitude()

  useEffect(() => {
    if (!userProfileLoading && userProfile) {
      setUserId(userProfile.user_id)
      setUserCreateTime(userProfile.create_time)
    }
  }, [userProfile, userProfileLoading, setUserCreateTime, setUserId])

  const isWhiteList = whiteLists.some((regex) => regex.test(pathname))

  if (isWhiteList && !auth0ProfileLoading) {
    return children
  }

  if (auth0ProfileLoading || userProfileLoading)
    return (
      <div className='size-full flex items-center justify-center text-text flex-1'>
        <Loading />
      </div>
    )

  if (!isLogin && !isWhiteList) {
    // if (pathname === '/' && getLocalStorage(CACHE_KEY_ONBOARDING_DATA) === null) {
    if (pathname === '/' || pathname === '/explore' || pathname === '/templates') {
      if (!isOldUser()) {
        router.replace(`/onboarding${window.location.search}`)
        return
      }
    }
    goAuth()
    return null
  }

  return children
}
