import Button from '@/components/button'
import Back from './_back'
import { PersonaFormProps } from './_common'
import { useCallback, useEffect, useState } from 'react'
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group'
import { cls } from '@/utils'
import useAmplitude from '@/hooks/useAmplitude'

const options = [
  { value: '0_18', label: 'Under 18' },
  { value: '18_25', label: '18 ~ 25' },
  { value: '26_30', label: '26 ~ 30' },
  { value: '31_35', label: '31 ~ 35' },
  { value: '36_50', label: '36 ~ 50' },
  { value: '50+', label: 'Over 50' },
]

const showConfirmBtn = false

export default function PersonaAgeGroupForm({
  onSubmit,
  onBack,
  value,
}: PersonaFormProps) {
  const [ageGroup, setAgeGroup] = useState(value?.age_group ?? '')

  const handleSubmit = useCallback(() => {
    onSubmit({ age_group: ageGroup })
  }, [ageGroup, onSubmit])

  const { track } = useAmplitude()
  useEffect(() => {
    track('view:persona:age-group')
  }, [track])

  const handleValueChange = useCallback(
    (v: string) => {
      setAgeGroup(v)

      if (!showConfirmBtn) {
        onSubmit({ age_group: v })
      }
    },
    [onSubmit],
  )

  return (
    <div className='size-full flex flex-col items-center justify-center'>
      <div className='w-full md:w-[640px] flex flex-col items-start justify-center'>
        <div
          className='w-full p-6 text-heading-lg font-bold'
          aria-label='title'
        >
          Your age group?
        </div>
        <div className='w-full px-6' aria-label='radio'>
          <RadioGroup
            value={String(ageGroup)}
            className='grid grid-cols-1 md:grid-cols-2 gap-4'
            onValueChange={handleValueChange}
          >
            {options.map(({ label, value }) => {
              const id = `persona-age-group-${value}`
              const active = value === ageGroup
              return (
                <div
                  key={value}
                  className={cls(
                    'flex items-center w-full justify-between h-[66px] rounded-lg border-2 border-b-4 border-solid border-border cursor-pointer hover:bg-surface-hover shadow-sm hover:border-border-hover',
                    active
                      ? 'bg-surface-active hover:bg-surface-active bg-opacity-10 border-border-active hover:border-border-active'
                      : 'bg-surface',
                  )}
                  onClick={active ? handleSubmit : undefined}
                >
                  <label
                    htmlFor={id}
                    className='size-full min-h-full pl-7 p-3 flex items-center text-heading-md font-bold cursor-pointer'
                  >
                    {label}
                  </label>
                  <RadioGroupItem
                    id={id}
                    className='hidden invisible'
                    value={value}
                  />
                </div>
              )
            })}
          </RadioGroup>
        </div>
        <div className='w-full p-6 pt-4 flex justify-between'>
          <Back onBack={onBack} />
          {showConfirmBtn ? (
            <Button
              variant='primary'
              disabled={!ageGroup}
              onClick={handleSubmit}
            >
              Confirm
            </Button>
          ) : (
            <div />
          )}
        </div>
      </div>
    </div>
  )
}
