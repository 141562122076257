import Button from '@/components/button'
import { PersonaFormProps } from './_common'
import IconRobot from '@/public/assets/persona/robot.svg'
import IconYes from '@/public/assets/yes.svg'
import IconNo from '@/public/assets/persona/no.svg'
import Back from './_back'
import { useCallback, useEffect, useState } from 'react'
import { cls } from '@/utils'
import Input from '@/components/input'
import { Checkbox } from '@/components/ui/checkbox'
import useAmplitude from '@/hooks/useAmplitude'

export default function PersonaExperiencedAIGCToolsForm({
  value,
  onSubmit,
  onBack,
}: PersonaFormProps) {
  const [showInput, setShowInput] = useState(false)
  const [experiencedAIGCTools, setExperiencedAIGCTools] = useState(
    value?.experienced_aigc_tools ?? '',
  )

  const [submitting, setSubmitting] = useState(false)

  const handleYes = useCallback(() => {
    setShowInput(true)
  }, [])

  const handleNo = useCallback(async () => {
    await onSubmit({ experienced_aigc_tools: '' })
  }, [onSubmit])

  const handleSubmit = useCallback(async () => {
    setSubmitting(true)
    try {
      await onSubmit({
        experienced_aigc_tools: showInput
          ? experiencedAIGCTools || 'not_specified'
          : '',
      })
    } catch (error) {
      setSubmitting(false)
      throw error
    }
    setSubmitting(false)
  }, [onSubmit, experiencedAIGCTools, showInput])

  const { track } = useAmplitude()
  useEffect(() => {
    track('view:persona:experienced-aigc-tools')
  }, [track])

  return (
    <div className='size-full flex items-center justify-center'>
      <div className='md:w-[480px] h-[363px]'>
        <div
          className='w-full p-6 text-heading-lg font-bold tracking-45'
          aria-label='title'
        >
          Experienced with Generative AI tools?
        </div>
        <div className='w-full p-6 pt-0 flex gap-5 h-[104px] items-center'>
          <div className='size-[75px]'>
            <IconRobot className='size-[75px]' />
          </div>
          <div className='text-heading-md font-bold'>
            Such as:
            <br />
            AI Chat Bot, Image, Video and Audio Generation, e.g. ChatGPT,
            Midjourney, etc.
          </div>
        </div>
        <div className='px-6 gap-4 w-full flex'>
          <Button
            className={cls(
              'p-3 flex items-center flex-1 w-full justify-start rounded-lg border-2 border-b-4 border-solid border-border hover:border-border-hover shadow-sm',
              showInput ? 'hidden' : '',
            )}
            onClick={handleYes}
          >
            <div className='flex w-full justify-start items-center gap-4'>
              <IconYes className='w-[34px] h-auto' />
              <span className='text-text text-heading-md font-bold'>Yes</span>
            </div>
          </Button>
          <Button
            className={cls(
              'p-3 flex items-center flex-1 w-full justify-start rounded-lg border-2 border-b-4 border-solid border-border hover:border-border shadow-sm',
              showInput ? 'hidden' : '',
            )}
            onClick={handleNo}
          >
            <div className='flex w-full justify-start items-center gap-4'>
              <IconNo className='w-[34px] h-auto' />
              <span className='text-text text-heading-md font-bold'>No</span>
            </div>
          </Button>
          <div
            className={cls(
              'flex flex-col w-full h-[118px] p-3 pb-4 gap-3 shadow-sm rounded-lg bg-surface-active border-2 border-b-4 border-border-active border-solid cursor-pointer',
              showInput ? '' : 'hidden',
            )}
            onClick={() => {
              setShowInput(false)
            }}
          >
            <div className='flex-1 flex gap-4 justify-between items-center'>
              <IconYes className='size-9' />
              <span className='flex-1 text-heading-md font-bold'>Yes</span>
              <div className='size-9 flex items-center justify-center'>
                <Checkbox
                  size='sm'
                  checked={showInput}
                  onCheckedChange={(checked) => setShowInput(!!checked)}
                />
              </div>
            </div>
            <Input
              className='h-10 border rounded-md focus-visible:outline-none focus-visible:border-border-active cursor-auto'
              autoComplete='off'
              placeholder='Input the tools (optional)'
              value={experiencedAIGCTools}
              onChange={(e) => setExperiencedAIGCTools((e.target as any).value)}
              onClick={(e) => e.stopPropagation()}
            />
          </div>
        </div>
        <div className='flex justify-between w-full items-center p-6 pt-4'>
          <Back className='' onBack={onBack} />
          <div>
            <Button
              variant='primary'
              loading={submitting}
              className={cls('rounded-md', showInput ? '' : 'hidden')}
              onClick={handleSubmit}
            >
              Confirm
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
