import { useRouter } from 'next/navigation'
import { CACHE_AUTH_REDIRECT_URL } from '@/constants'
import { useCallback } from 'react'
import { useSetAtom } from 'jotai'
import { loginDialogOpenAtom } from '@/atoms'

export interface useGoAuthOptions {
  redirectUrl?: string
}

const useGoAuth = (options?: useGoAuthOptions) => {
  const router = useRouter()
  const setLoginDialogOpen = useSetAtom(loginDialogOpenAtom)

  const goAuth = useCallback(() => {
    const redirectUrl =
      options?.redirectUrl ??
      `${window.location.pathname}${window.location.search}`
    sessionStorage.setItem(CACHE_AUTH_REDIRECT_URL, redirectUrl)
    setLoginDialogOpen(false)
    router.replace(`/auth/signin${window.location.search}`)
  }, [router, setLoginDialogOpen, options])

  return goAuth
}

export default useGoAuth
