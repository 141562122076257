'use client'

import WithMSW from '@/components/with-msw'
import { ModalProvider } from '@/components/modal/provider'
import AmplitudeContextProvider from '@/contexts/amplitude'
import Guard from '@/components/with-guard'
import { BlockedDialog } from '@/components/blocked'
import VConsole from '@/components/vconsole'
import Persona from '../persona'
import { useCachedMyProfile } from '@/hooks/useMyProfile'
import { useAtomValue, useSetAtom } from 'jotai'
import {
  auth0Loading,
  followUsDialogOpenAtom,
  getTobCreditDialogOpenAtom,
  surveyCompleteAtom,
  versionDialogOpenAtom,
} from '@/atoms'
import { useCachedSwitches } from '@/hooks/useSwitches'
import { usePathname } from 'next/navigation'
import WithAuth0 from '../with-auto0'
import Loading from '@/components/loading'
import LoginDialog from '@/components/login-dialog'
import { SubscriptionDialog } from '@/components/subscription-dialog'
import { SubscriptionRemind } from '@/components/subscription-remind'
import GetCreditDialog from '@/components/get-credit-dialog'
import { CreditProvider, useCreditValue } from '@/hooks/useCredit'
import InsufficientDialog from '@/components/insufficient-dialog'
import VersionDialog from '@/components/version-dialog'
import { useEffect } from 'react'
import dayjs from 'dayjs'
import { getLocalStorage } from '@/utils'
import GetTobCreditDialog from '@/components/get-tob-credit-dialog'
import FollowUsDialog from '@/components/follow-us-dialog'

export default function WithCSR({ children, modal }: { children: React.ReactNode; modal?: React.ReactNode }) {
  const { data: profile } = useCachedMyProfile()
  const surveyComplete = useAtomValue(surveyCompleteAtom)
  const setVersionDialogOpen = useSetAtom(versionDialogOpenAtom)
  const { data: switches, isValidating: switchesLoading } = useCachedSwitches()
  const isAuth0Loading = useAtomValue(auth0Loading)
  const CreditModal = useCreditValue()

  const pathname = usePathname()

  useEffect(() => {
    if (!profile) return
    const showTime = dayjs(getLocalStorage('versionShow') || undefined)
    if (dayjs() < dayjs('2024-7-30') && (!getLocalStorage('versionShow') || dayjs().diff(showTime, 'day') > 7)) {
      setVersionDialogOpen(true)
    }
  }, [profile, setVersionDialogOpen])

  const showSurvey =
    !!profile &&
    !profile?.survey_completed &&
    surveyComplete === null &&
    switches?.onboarding_survey &&
    !pathname?.startsWith('/auth/')

  const loading = switchesLoading && !switches && isAuth0Loading

  const getCreditDialogOpen = useAtomValue(getTobCreditDialogOpenAtom)
  const followUsDialogOpen = useAtomValue(followUsDialogOpenAtom)

  return (
    <AmplitudeContextProvider>
      <WithMSW>
        <WithAuth0>
          <Guard>
            <ModalProvider>
              <CreditProvider value={CreditModal}>
                {loading ? (
                  <div className='size-full flex items-center justify-center'>
                    <Loading />
                  </div>
                ) : showSurvey ? (
                  <Persona />
                ) : (
                  children
                )}
                {modal}
                <VersionDialog />
                <BlockedDialog />
                <LoginDialog />
                <SubscriptionDialog />
                <SubscriptionRemind />
                <GetCreditDialog />
                {getCreditDialogOpen && <GetTobCreditDialog />}
                {followUsDialogOpen && <FollowUsDialog />}
                <InsufficientDialog />
              </CreditProvider>
            </ModalProvider>
          </Guard>
        </WithAuth0>
      </WithMSW>
      <VConsole />
    </AmplitudeContextProvider>
  )
}
