import Button from '@/components/button'
import Back from './_back'
import { PersonaFormProps } from './_common'
import Input from '@/components/input'
import { useCallback, useEffect, useState } from 'react'
import useAmplitude from '@/hooks/useAmplitude'

export default function PersonaNicknameForm({
  onSubmit,
  onBack,
  value,
}: PersonaFormProps) {
  const [nickname, setNickname] = useState(value?.nickname ?? '')

  const handleSubmit = useCallback(() => {
    onSubmit({ nickname })
  }, [nickname, onSubmit])

  const { track } = useAmplitude()
  useEffect(() => {
    track('view:persona:nick-name')
  }, [track])

  return (
    <div className='size-full flex flex-col items-center justify-center'>
      <div className='w-full md:w-[480px] flex flex-col items-start justify-center'>
        <div
          className='w-full p-6 text-heading-lg font-bold'
          aria-label='title'
        >
          Please enter your nickname
        </div>
        <div className='w-full px-6' aria-label='input'>
          <Input
            className='border-2 rounded-md bg-surface shadow-sm'
            placeholder='Nickname'
            value={nickname}
            autoComplete='off'
            onChange={(e) => {
              setNickname((e.target as any).value)
            }}
          />
        </div>
        <div className='w-full p-6 pt-4 flex justify-between'>
          <Back onBack={onBack} />
          <Button
            variant='primary'
            className='rounded-md'
            disabled={!nickname}
            onClick={handleSubmit}
          >
            Confirm
          </Button>
        </div>
      </div>
    </div>
  )
}
