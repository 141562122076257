import Button from '@/components/button'
import Back from './_back'
import { PersonaFormProps } from './_common'
import { useCallback, useEffect, useState } from 'react'
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group'
import { cls } from '@/utils'
import { PersonaRole } from '@/types'
import IconContentCreator from '@/public/assets/persona/movies.svg'
import IconProgrammer from '@/public/assets/persona/monitor.svg'
import IconArtist from '@/public/assets/persona/paint-palette.svg'
import IconDesigner from '@/public/assets/persona/layers.svg'
import IconMarketer from '@/public/assets/persona/presentation.svg'
import IconFilmMaker from '@/public/assets/persona/film.svg'
import IconEducator from '@/public/assets/persona/graduation-cap.svg'
import IconOther from '@/public/assets/persona/loading.svg'
import useAmplitude from '@/hooks/useAmplitude'

const options = [
  {
    value: 'content_creator',
    icon: IconContentCreator,
    label: 'Content Creator',
  },
  { value: 'programmer', icon: IconProgrammer, label: 'Programmer' },
  { value: 'artist', icon: IconArtist, label: 'Artist' },
  { value: 'designer', icon: IconDesigner, label: 'Designer' },
  { value: 'marketer', icon: IconMarketer, label: 'Marketer' },
  { value: 'film_maker', icon: IconFilmMaker, label: 'Film Maker' },
  { value: 'educator', icon: IconEducator, label: 'Educator' },
  { value: 'other', icon: IconOther, label: 'Other' },
]

const showConfirmBtn = false

export default function PersonaRoleForm({
  onSubmit,
  onBack,
  value,
}: PersonaFormProps) {
  const [role, setRole] = useState<PersonaRole | ''>(value?.role ?? '')

  const { track } = useAmplitude()
  useEffect(() => {
    track('view:persona:role')
  }, [track])

  const handleSubmit = useCallback(() => {
    if (role) {
      onSubmit({ role })
    }
  }, [role, onSubmit])

  const handleValueChange = useCallback(
    (v: string) => {
      setRole(v as PersonaRole)

      if (!showConfirmBtn) {
        onSubmit({ role: v as PersonaRole })
      }
    },
    [onSubmit],
  )

  return (
    <div className='size-full flex flex-col items-center justify-center'>
      <div className='w-full md:w-[640px] flex flex-col items-start justify-center'>
        <div
          className='w-full p-6 text-heading-lg font-bold'
          aria-label='title'
        >
          What best describes you?
        </div>
        <div className='w-full px-6' aria-label='radio'>
          <RadioGroup
            value={String(role)}
            className='grid grid-cols-1 md:grid-cols-2 gap-4'
            onValueChange={handleValueChange}
          >
            {options.map(({ label, value, icon: Icon }) => {
              const id = `persona-role-${value}`
              const active = value === role
              return (
                <div
                  key={value}
                  className={cls(
                    'flex items-center size-full justify-between h-[66px] rounded-lg border-2 border-b-4 border-solid border-border shadow-sm cursor-pointer hover:bg-surface-hover hover:border-border-hover',
                    active
                      ? 'bg-surface-active hover:bg-surface-active bg-opacity-10 border-border-active hover:border-border-active'
                      : 'bg-surface',
                  )}
                  onClick={active ? handleSubmit : undefined}
                >
                  <label
                    htmlFor={id}
                    className='size-full min-h-full flex items-center gap-4 h-9 p-3 cursor-pointer'
                  >
                    <Icon className='w-[34px] h-auto max-h-[34px]' />
                    <span className='text-heading-md font-bold'>{label}</span>
                  </label>
                  <RadioGroupItem
                    id={id}
                    className='hidden invisible'
                    value={value}
                  />
                </div>
              )
            })}
          </RadioGroup>
        </div>
        <div className='w-full p-6 pt-4 flex justify-between'>
          <Back onBack={onBack} />
          {showConfirmBtn ? (
            <Button
              variant='primary'
              className='rounded-md'
              disabled={!role}
              onClick={handleSubmit}
            >
              Confirm
            </Button>
          ) : (
            <div />
          )}
        </div>
      </div>
    </div>
  )
}
